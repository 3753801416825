import "./App.css";
// import { FaMoon } from "react-icons/fa";
// import { FaMoon } from "react-icons/fa";
import DevOpsgif from "./Images/DevOps.gif";
import { SiLinkedin } from "react-icons/si";
import { SiFiverr } from "react-icons/si";
import { SiUpwork } from "react-icons/si";
import { SiTwitter } from "react-icons/si";
import { SiGithub } from "react-icons/si";
import { SiFacebook } from "react-icons/si";
import { SiMaildotru } from "react-icons/si";
import Skillsgif from "./Images/Skills.gif";
import Cisco_ItCS from "./Images/Cisco-ItCS.png";
import Cisco_Logo from "./Images/Cisco-Logo.png";
import Fortinet_Logo from "./Images/Fortinet-Logo.svg";
import OCI_AA2019 from "./Images/OCI_AA2019.png";
import OCI_DA2020 from "./Images/OCI_DA2020.png";
import OCI_FA2020 from "./Images/OCI_FA2020.png";
import OCI_OA219 from "./Images/OCI_OA219.png";
import OCI_PA2019 from "./Images/OCI_PA2019.png";
import { GoLinkExternal } from "react-icons/go";
import { FaDownload } from "react-icons/fa";
import me from "./Images/MyImage.png";

function App() {
  const mobileScreenMediaQuery1 = window.matchMedia("(max-width: 825px)");
  const largeScreenMediaQuery1 = window.matchMedia("(min-width: 825px)");

  window.onload = () => {
    document.getElementById("navbarIcon").style.display = "none";
    document.getElementById("navFull").style.display = "none";
    setInterval(() => {
      if (mobileScreenMediaQuery1.matches) {
        document.getElementById("navbarIcon").style.display = "flex";
      } else if (largeScreenMediaQuery1.matches) {
        document.getElementById("navFull").style.display = "flex";
      }
    }, 4500);
    setInterval(() => {
      document.getElementById("main").classList.remove("displayNone");
      document.getElementById("footer").classList.remove("displayNone");
    }, 4600);
  };

  window.onresize = () => {
    let windowHeight = Math.ceil(window.outerHeight / 10);
    let headerHeight = document.getElementById("logo").offsetHeight - 10;
    if (headerHeight < windowHeight) {
      if (mobileScreenMediaQuery1.matches) {
        document.getElementById("navbarIcon").style.display = "flex";
        document.getElementById("navFull").style.display = "none";
      } else {
        document.getElementById("navbarIcon").style.display = "none";
        document.getElementById("navFull").style.display = "flex";
      }
      // console.log("Success :" + window.outerHeight / 10);
    } else {
      // console.log("Fail :" + window.outerHeight / 10);
    }
  };

  function navbarIconAnimate() {
    document
      .getElementById("navbarIconContainer")
      .classList.toggle("navbarIconChange");
  }

  let displayNav = () => {
    document.getElementById("navSmall").classList.toggle("displayNone");
    document.getElementById("main").classList.toggle("displayNone");
    document.getElementById("footer").classList.toggle("displayNone");
  };

  var toDarkMode = () => {
    document.getElementById("body").style.backgroundColor = "black";
    document.getElementById("main").style.backgroundColor = "black";
    document.getElementById("websiteSmallMode").innerHTML = "Dark";
    document.getElementById("websiteFullMode").innerHTML = "Dark";
  };

  var toLightMode = () => {
    document.getElementById("body").style.backgroundColor = "white";
    document.getElementById("main").style.backgroundColor = "white";
    document.getElementById("websiteSmallMode").innerHTML = "Light";
    document.getElementById("websiteFullMode").innerHTML = "Light";
  };

  var changeMode = () => {
    switch (document.getElementById("body").style.backgroundColor) {
      case (document.getElementById("body").style.backgroundColor = "black"):
        toLightMode();
        break;
      case (document.getElementById("body").style.backgroundColor = "white"):
        toDarkMode();
        break;
    }
  };

  return (
    <div id="body">
      <header>
        <div id="logo">
          <h1 id="h1">{"{thenabeelhassan}"}</h1>
        </div>
        <div id="navbar">
          <div id="navbarIcon" onClick={displayNav}>
            <div
              className="navbarIconContainer"
              id="navbarIconContainer"
              onClick={navbarIconAnimate}
            >
              <div className="navbarIconBar1"></div>
              <div className="navbarIconBar2"></div>
              <div className="navbarIconBar3"></div>
            </div>
          </div>
          <div id="navSmall" className="displayNone">
            <nav>
              <a href="#">
                <div className="navSmallItem">Skills</div>
              </a>
              <a href="#">
                <div className="navSmallItem">Work Experiences</div>
              </a>
              <a href="#">
                <div className="navSmallItem">Open Source</div>
              </a>
              <a href="#">
                <div className="navSmallItem">Achievements</div>
              </a>
              <a href="#">
                <div className="navSmallItem">Contact Me</div>
              </a>
              <div className="navSmallItem modeDiv" onClick={changeMode}>
                mode:
                <pre>
                  <span id="websiteSmallMode"> Dark</span>
                </pre>
              </div>
            </nav>
          </div>
          <div id="navFull">
            <nav>
              <a href="#skills">
                <div className="navFullItem">Skills</div>
              </a>
              <a href="#experience">
                <div className="navFullItem">Work Experiences</div>
              </a>
              <a href="#openSource">
                <div className="navFullItem">Open Source</div>
              </a>
              <a href="#achievements">
                <div className="navFullItem">Achievements</div>
              </a>
              <a href="#contact">
                <div className="navFullItem">Contact Me</div>
              </a>
              <div className="navFullItem modeDiv" onClick={changeMode}>
                mode:
                <pre>
                  <span id="websiteFullMode"> Dark</span>
                </pre>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <main id="main" className="displayNone">
        <section id="intro" className="mainSections">
          <div className="SectionsHeading">
            <h2>Hi folks, I am Nabeel Hassan !!!</h2>
          </div>
          <div className="SectionsContent">
            <div id="introImageDivSmall">
              <img src={DevOpsgif} />
            </div>
            <div id="introContentDiv">
              <p>
                A passionate DevOps Engineer having an experience of building
                Web and applications with JavaScript / Reactjs / Nodejs and some
                other cool libraries and frameworks alongside creating and
                managing environments on Cloud Platforms like Amazon Web
                Services, Google Cloud Platform, Microsoft Azure and others.
              </p>
              <div className="contactLinksDiv">
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.linkedin.com/in/thenabeelhassan/"
                    target="_blank"
                  >
                    <SiLinkedin />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.fiverr.com/thenabeelhassan"
                    target="_blank"
                  >
                    <SiFiverr />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.upwork.com/freelancers/~01b7b0111ad108b10d"
                    target="_blank"
                  >
                    <u>
                      <SiUpwork />
                    </u>
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="https://twitter.com/thenabeelhassan" target="_blank">
                    <SiTwitter />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="https://github.com/thenabeelhassan" target="_blank">
                    <SiGithub />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.facebook.com/thenabeelhassan"
                    target="_blank"
                  >
                    <SiFacebook />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="mailto:info@thenabeelhassan.com">
                    <SiMaildotru />
                  </a>
                </div>
              </div>
            </div>
            <div id="introImageDivFull">
              <img src={DevOpsgif} />
            </div>
          </div>
        </section>
        <section id="skills" className="mainSections">
          <div className="SectionsHeading">
            <h2>My Knacks</h2>
          </div>
          <div className="SectionsContent">
            <div id="skillsImageDiv">
              <div>
                <p>
                  FUNPACK FULL STACK DEVELOPMENT WITH COOL CLOUD PLATFORMS AND
                  SOME POWERFULL ADMINISTRATIVE TOOLS
                </p>
              </div>
              <div>
                <img src={Skillsgif} />
              </div>
            </div>
            <div id="skillsContentDiv">
              <ul>
                <li>
                  Developing highly interactive Front end / User Interfaces for
                  your web applications.
                </li>
                <li>Containerizing application to Docker.</li>
                <li>
                  Deployments on Cloud Platforms and Environments like AWS / GCP
                  / Azure / Firebase / Kubernetes.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="experience" className="mainSections">
          <div className="SectionsHeading">
            <h2>Work Experiences</h2>
          </div>
          <div id="experienceContentDiv">
            <div className="experienceContent">
              <div className="experienceContentImg"></div>
              <div className="experienceContentDetails">
                <h3>Fiverr / Upwork</h3>
                <h4>Cloud Architect Freelancer</h4>
                <h4>May 2021 - Present</h4>
                <ul>
                  <li>Management of client's AWS Cloud Infrastructure.</li>
                  <li>
                    Configuration of AWS Compute, Storage, Network, Indentity,
                    CDN, Kubernetes and Container services for clients.
                  </li>
                </ul>
              </div>
            </div>
            <div className="experienceContent">
              <div className="experienceContentImg"></div>
              <div className="experienceContentDetails">
                <h3>Catcos / PrimeHR</h3>
                <h4>IT Support Officer for State Bank of Pakistan</h4>
                <h4>November 2019 - Present</h4>
                <ul>
                  <li>
                    Managing Microsoft Active Directory's OUs, Users and Groups.
                  </li>
                  <li>
                    Managing Microsoft Exchange's User Mailboxes, Distribution
                    Lists, Shared Mailboxes and Databases.
                  </li>
                  <li>
                    Configuration and Management of Symantec Endpoint Protection
                    Antivirus.
                  </li>
                  <li>
                    Configuration and Management of Symantec Endpoint Detection
                    Response.
                  </li>
                  <li>
                    Configuration and Management of Cisco Web Secuirty
                    Appliances.
                  </li>
                  <li>
                    Configuration and Management of VMs in VMware and Hyper-V
                    environment.
                  </li>
                  <li>Administration of Windows Server 2012, 2016 and 2019.</li>
                  <li>Monitoring of Data Centers, Network and UPS room.</li>
                  <li>Execution of End of Day.</li>
                </ul>
              </div>
            </div>
            <div className="experienceContent">
              <div className="experienceContentImg"></div>
              <div className="experienceContentDetails">
                <h3>Captivators Production</h3>
                <h4>Graphics & Web Desinger</h4>
                <h4>December 2018 - Present</h4>
                <ul>
                  <li>Management of Organization's websites.</li>
                  <li>Management of Organization's Social Media profiles.</li>
                  <li>Management of client's websites.</li>
                  <li>Management of client's Social Media profiles.</li>
                  <li>Creating Artworks for Organization.</li>
                  <li>Creating Artworks for clients.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="openSource" className="mainSections">
          <div className="SectionsHeading">
            <h2>Open Source Projects</h2>
          </div>
          <div id="openSourceContentDiv">
            <div className="openSourceContent">
              <div id="Project1">
                <p>Coming Soon</p>
              </div>
            </div>
            <div className="openSourceContent">
              <div id="Project2">
                <p>Coming Soon</p>
              </div>
            </div>
            <div className="openSourceContent">
              <div id="Project3">
                <p>Coming Soon</p>
              </div>
            </div>
          </div>
        </section>
        <section id="achievements" className="mainSections">
          <div className="SectionsHeading">
            <h2>Achievements And Certifications</h2>
          </div>
          <div id="achievementsContentDiv">
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={OCI_OA219} />
              </div>
              <div className="achievementsDetails">
                <h3>Oracle</h3>
                <h4>
                  Oracle Cloud Infrastructure 2019 Cloud Operations Associate
                  (May 2020)
                </h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={OCI_AA2019} />
              </div>
              <div className="achievementsDetails">
                <h3>Oracle</h3>
                <h4>
                  Oracle Cloud Infrastructure 2019 Architect Associate (May
                  2020)
                </h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={OCI_PA2019} />
              </div>
              <div className="achievementsDetails">
                <h3>Oracle</h3>
                <h4>
                  Oracle Cloud Infrastructure 2019 Architect Professional (May
                  2020)
                </h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={OCI_DA2020} />
              </div>
              <div className="achievementsDetails">
                <h3>Oracle</h3>
                <h4>
                  Oracle Cloud Infrastructure Developer 2020 Associate (May
                  2020)
                </h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={OCI_FA2020} />
              </div>
              <div className="achievementsDetails">
                <h3>Oracle</h3>
                <h4>
                  Oracle Cloud Infrastructure Foundations 2020 Associate (May
                  2020)
                </h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={Fortinet_Logo} />
              </div>
              <div className="achievementsDetails">
                <h3>Fortinet</h3>
                <h4>NSE 2 Network Security Associate (May 2020)</h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={Cisco_Logo} />
              </div>
              <div className="achievementsDetails">
                <h3>Cisco</h3>
                <h4>NDG Linux Unhatched (April 2020)</h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={Fortinet_Logo} />
              </div>
              <div className="achievementsDetails">
                <h3>Fortinet</h3>
                <h4>NSE 2 Network Security Associate (April 2020)</h4>
              </div>
            </div>
            <hr></hr>
            <div className="achievementsContent">
              <div className="achievementsLogo">
                <img src={Cisco_ItCS} />
              </div>
              <div className="achievementsDetails">
                <h3>Cisco</h3>
                <h4>Introduction to Cybersecurity (April 2020)</h4>
              </div>
            </div>
            <hr></hr>
          </div>
        </section>
        <section id="contact" className="mainSections">
          <h2>Get In Touch</h2>
          <div id="contactInfo">
            <div id="myImageDiv">
              <img id="myImage" src={me} />
              <h2>NABEEL HASSAN</h2>
            </div>
            <div id="contactContents">
              <p>GOT SOME CONVERSATION OR ANY PROJECT, FEEL FREE TO PING ME.</p>
              <div className="ContactButtons">
                Resume:
                <a href="https://resume.thenabeelhassan.com/" target="_blank">
                  <button>
                    view <GoLinkExternal />
                  </button>
                </a>
                <button disabled title="Available Soon">
                  <s>
                    download <FaDownload className="FaDownload" />
                  </s>
                </button>
              </div>
              <div className="contactLinksDiv">
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.linkedin.com/in/thenabeelhassan/"
                    target="_blank"
                  >
                    <SiLinkedin />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.fiverr.com/thenabeelhassan"
                    target="_blank"
                  >
                    <SiFiverr />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.upwork.com/freelancers/~01b7b0111ad108b10d"
                    target="_blank"
                  >
                    <u>
                      <SiUpwork />
                    </u>
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="https://twitter.com/thenabeelhassan" target="_blank">
                    <SiTwitter />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="https://github.com/thenabeelhassan" target="_blank">
                    <SiGithub />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a
                    href="https://www.facebook.com/thenabeelhassan"
                    target="_blank"
                  >
                    <SiFacebook />
                  </a>
                </div>
                <div className="contactLinksImsgs">
                  <a href="mailto:info@thenabeelhassan.com">
                    <SiMaildotru />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer" className="displayNone">
        Copy rights
      </footer>
    </div>
  );
}

export default App;
